<template>
	<div class="m-3">
		<b-input-group class="mb-3">
			<template #prepend>
				<b-input-group-text><BIconSearch /></b-input-group-text>
			</template>
			<b-form-input v-model="filter" placeholder="Quick search"></b-form-input>
			<b-button class="ml-5" @click="copyTable()">Copy Table to Clipboard</b-button>
		</b-input-group>
		<b-table class="table-wrapper shadow" striped sticky-header style="" :items="words" :fields="fields" :filter="filter"  :filter-included-fields="['word']">
			<!-- Colored unique column -->
			<template #cell(unique)="data">
				<b class="text-info">{{data.value.toLocaleString()}}</b>
			</template>
			<!-- Title -->
			<template #cell(title)="data">
				{{data.value.toLocaleString()}}
			</template>
			<!-- Abstract -->
			<template #cell(abstract)="data">
				{{data.value.toLocaleString()}}
			</template>
			<!-- Keywords -->
			<template #cell(keywords)="data">
				{{data.value.toLocaleString()}}
			</template>
			<!-- Pad out progress bar column with min width -->
			<template #head(points)="data">
				<div style="width: 200px">
					{{data.label}}
				</div>
			</template>
			<!-- Custom progress bar for points -->
			<template #cell(points)="data">
				<b-progress height="20px" class="w-100" :max="maxPoints">
					<b-progress-bar :value="data.value">
						<span>{{data.value.toLocaleString()}}</span>
					</b-progress-bar>
				</b-progress>
      </template>
		</b-table>
	</div>
</template>

<script>
import { BTable, BProgress, BProgressBar, BInputGroup, BInputGroupText, BFormInput, BIconSearch, BButton } from 'bootstrap-vue';

export default {
	components: {
		BTable,
		BProgress,
		BProgressBar,
		BInputGroup,
		BInputGroupText,
		BFormInput,
		BIconSearch,
		BButton
	},
	props: {
		words: Array
	},
	data() {
		return {
			fields: [
				{ key: 'word', sortable: true },
				{ key: 'unique', sortable: true },
				{ key: 'title', sortable: true },
				{ key: 'abstract', sortable: true },
				{ key: 'keywords', sortable: true },
				{ key: 'points', sortable: true}
			],
			filter: ""
		}
	},
	computed: {
		maxPoints() {
			return this.words[0].points;
		}
	},
	methods: {
		generateStringTSVTable() {
			let tsvString = "";
			// Construct headers
			tsvString += this.fields.map(field => field.key).join("\t");
			tsvString += "\n"
			// Construct table
			for (let word of this.words) {
				tsvString += this.fields.map(field => word[field.key]).join("\t");
				tsvString += "\n"
			}
			return tsvString;
		},
		generateStringHtmlTable() {
			let tableString = "<table><tr>";
			// Construct headers
			for (let field of this.fields) {
				tableString += `<th>${field.key}</th>`
			}
			tableString += "</tr>"
			// Construct table body
			for (let word of this.words) {
				tableString += "<tr>"
				for (let field of this.fields) {
					tableString += `<td>${word[field.key]}</td>`
				}
				tableString += "</tr>"
			}
			tableString += "</table>"
			return tableString;
		},
		async copyTable() {
			if (!navigator.clipboard || !window.ClipboardItem) {
				this.fallbackCopyTable();
				return;
			}
			try {
				await navigator.clipboard.write([
					new window.ClipboardItem({
						'text/html': new Blob([this.generateStringHtmlTable()], {type: 'text/html'}),
						'text/plain': new Blob([this.generateStringTSVTable()], {type: 'text/plain'})
					})
				]);
				alert("Table copied to clipboard")
			} catch (err) {
				alert('Copy: Oops, unable to copy', err)
				console.error('Copy: Oops, unable to copy', err);
			}
		},
		fallbackCopyTable() {
			var textArea = document.createElement("textarea");
			textArea.value = this.generateStringTSVTable();

			// Avoid scrolling to bottom
			textArea.style.top = "0";
			textArea.style.left = "0";
			textArea.style.position = "fixed";

			document.body.appendChild(textArea);
			textArea.focus();
			textArea.select();

			try {
				var successful = document.execCommand('copy');
				var msg = successful ? 'successful' : 'unsuccessful';
				console.log('Fallback: Copying text command was ' + msg);
			} catch (err) {
				console.error('Fallback: Oops, unable to copy', err);
				alert('Fallback: Oops, unable to copy', err)
			}

			document.body.removeChild(textArea);
			alert("Table copied to clipboard");
		}
	}
}
</script>

<style scoped>
.table-wrapper {
	background-color: #fff;
	border: solid black 1px;
	max-height: calc(100vh - 150px);
}
</style>