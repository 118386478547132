const getDefault = () => {
	return {
		// Array of words and their score
		words: null
	}
}

const state = getDefault();

const getters = {
	// Get the words array
	getWords: state => {
		return state.words;
	},
}

const mutations = {
	reset(state) {
		Object.assign(state, getDefault());
	},
	// Set words array
	setWords(state, words) {
		// Set metadata
		state.words = words;
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations
}