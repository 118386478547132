/* eslint-disable no-useless-escape */
var _ = require('lodash');

Object.filter = (obj, predicate) =>
	Object.keys(obj)
		.filter( key => predicate(obj[key]) )
		.reduce( (res, key) => (res[key] = obj[key], res), {} );

module.exports = function(task, references) {
	// Object to store word freq data in
	var words = {};
	// Sanity checks {{{
	_.defaults(task.settings, {
		deburr: true,
		weights: { // Indicates the fields to be extracted and their weights
			title: 1,
			abstract: 1,
			keywords: 1,
		},
		ignore: {
			common: true,
			numbers: true,
			duplicates: false,
		},
		min: {
			points: 3,
			unique: 0,
		},
		max: {
			results: 500, // Anything above this limit gets truncated by the resultsTruncate field
			resultsTruncate: 'points',
		},
		combineWords: 2, // How many word combinations should be examined (1=one word,2=two words etc.)
	});

	if (!task.settings) throw new Error('.settings object must be present for request');
	if (task.settings.combineWords > 5) throw new Error('combineWords has a maximum of 5');
	if (task.settings.max.results > 500) throw new Error('Maximum number of references is too high');
	// Clear words object
	words = {};
	// }}}

	// Worker {{{
	references.forEach(function(ref) {
		var uniques = {}; // Words detected in this reference

		_.keys(task.settings.weights).forEach(function(key) {
			if (!ref[key]) return;

			var counted = {}; // Object `${sentence}`=>true storage of keys we have already seen
			var lastWords = [];

			_(
				_.isArray(ref[key]) ? // Is an array - split each element by whitespace
					_(ref[key])
						.map(x => x.split(/\s+/))
						.flatten()
						.value()
					: ref[key].split(/[\s\=\+\-\?\!\@\#\$\%\^\&\*\(\)\[\]\{\}\;\:\"\<\>\,\.\/]+/) // Is a string - split by whitespace or punctuation
			)
				.map(v => v.toLowerCase()) // Lower case everything
				.map(v => task.settings.deburr ? _.deburr(v) : v) // Deburr?
				.map(v => v.replace(/[\=\+\-\?\!\@\#\$\%\^\&\*\(\)\[\]\{\}\;\:\'\"\<\>\,\.\/]+/g, '')) // Strip punctuation
				.forEach(function(word) {
					// Ignore rules {{{
					if (
						!word || // Is blank
						( // Is a common word?
							task.settings.ignore.common &&
							/^(a|also|am|an|and|any|are|as|at|be|been|but|by|can|could|did|do|for|from|get|had|has|have|he|him|i|if|in|into|is|it|its|itself|last|may|me|met|more|n|no|not|p|of|on|only|or|our|over|see|set|she|should|some|such|than|that|them|then|their|there|these|the|they|this|to|up|upon|use|used|was|well|were|which|who|will|with|we|v|vs)$/.test(word)
						) ||
						( // Is a number?
							task.settings.ignore.numbers &&
							(
								/^[0-9\.,]+$/.test(word) ||
								/^(one|two|three|four|five|six|seven|eight|nine|ten|eleven|twelve)/.test(word) ||
								/(one|two|three|four|five|six|seven|eight|nine|teen)$/.test(word) ||
								/^(twenty|thirty|fourty|fifty|sixty|seventy|eighty|ninety)/.test(word)
							)
						)
					) {
						lastWords = []; // Reset buffer
						return;
					}
					// }}}


					lastWords.push(word); // Add last word to stack
					if (lastWords.length > task.settings.combineWords) lastWords.shift(); // Turn stack into circular array where we clip from the beginning (FILO)

					_.times(task.settings.combineWords, function(offset) {
						if (lastWords.length - 1 < offset) return;
						var sentence = lastWords.slice(0 - (offset+1));

						var wordGroup = sentence.join(' ');

						if (!words[wordGroup]) {
							words[wordGroup] = {
								points: 0,
								unique: 0,
							};
							_.keys(task.settings.weights).forEach(function(key) {
								words[wordGroup][key] = 0;
							});
						}

						if (!task.settings.ignore.duplicates || !counted[wordGroup]) { // Check to see if word is already counted if ignoring duplicates
							words[wordGroup].points += (task.settings.weights[key] || 1);
							words[wordGroup][key]++;
							counted[wordGroup] = true; // Keep track of whether we have seen this word grouping before in this reference so we dont count it twice
							if (!uniques[wordGroup]) {
								words[wordGroup].unique++;
								uniques[wordGroup] = true;
							}
						}
					});
				});
		});

		// Update progress
		task.progress.current++;
	})

	// Flatten entire structure into a collection
	_.map(words, function(data, word) {
		data.word = word;
		return data;
	})

	// Truncate if we have too many results
	if (Object.keys(words).length > task.settings.max.results) {
		words = _(words)
			.sortBy(task.settings.max.resultsTruncate)
			.reverse()
			.slice(0, task.settings.max.results)
			.value()
	}

	// Apply settings.min
	if (task.settings.min.points || task.settings.min.unique) {
		words = Object.filter(words, function(word) {
			return !(
				(task.settings.min.points && word.points < task.settings.min.points) ||
				(task.settings.min.unique && word.unique < task.settings.min.unique)
			)
		});
	}
	// }}}

	// Finish {{{
	return Object.values(words);
	// task.result = {
	// 	fields: [
	// 		{id: 'title', title: 'Title'},
	// 		{id: 'abstract', title: 'Abstract'},
	// 		{id: 'keywords', title: 'Keywords'},
	// 	],
	// 	words: words,
	// };
	// return task;
	// }}}
};
