<template>
	<div id="app">
		<!-- Navbar -->
		<TheNavbar :project-open="!!words" />
		<!-- File Upload -->
		<b-container v-if="!words">
			<FileUpload />
			<SettingsModal class="mt-3" />
			<BaseProgressBar v-if="isLoading" class="mt-3" />
		</b-container>
		<!-- Display Table -->
		<div v-if="words">
			<BaseTable :words="words" />
		</div>
	</div>
</template>

<script>
import TheNavbar from './components/TheNavbar.vue';
import BaseProgressBar from './components/BaseProgressBar.vue';
import FileUpload from './components/FileUpload.vue';
import BaseTable from './components/BaseTable.vue';
import { mapGetters } from 'vuex';
import SettingsModal from './components/SettingsModal.vue';

import { BContainer } from 'bootstrap-vue';

export default {
	name: 'App',
		components: {
		TheNavbar,
		BaseProgressBar,
		FileUpload,
		BaseTable,
		SettingsModal,
		BContainer
	},
	data() {
		return {
			isLoading: false
		}
	},
	computed: {
		...mapGetters({
			words: "words/getWords"
		})
	},
	mounted() {
		// Set loading status
		this.$root.$on('set-loading', isLoading => this.isLoading = isLoading);
	}
}
</script>

<style>
#app {
	min-height: 100vh;
	background-color: rgb(230, 230, 230);
}
</style>
