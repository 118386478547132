import Vue from 'vue'
import App from './App.vue'
import store, { teraSyncPlugin } from './store'

// Bootstrap Vue
import './theme.scss'

// Import $tera / @iebh/Tera-fy global service
// NOTE: See bottom of file inside main async() init loop for when TeraFy actually boots
import TeraFy from "@iebh/tera-fy/dist/terafy.es2019.js"; // FIX: Use annoyingly old and specific version as Babel struggles with ESNEXT class imports
import TerafyVue from "@iebh/tera-fy/dist/plugin.vue2.es2019.js";

let terafy = new TeraFy()
	.set("devMode", process.env.VUE_APP_TERAFY_DEV)
	.setIfDev("verbosity", process.env.VUE_APP_TERAFY_VERBOSITY)
	.use(TerafyVue); // Add the Vue plugin

Vue.config.productionTip = false;

(async ()=> {
	const app = window.app = new Vue({
		render: h => h(App),
		store,
		created() {
			// Set up the sync plugin
			teraSyncPlugin(store).setVueInstance(this)
		},
		beforeDestroy() {
			// Cleanup
			teraSyncPlugin(store).destroy()
		}
	});

	// Boot teraFy + require project + pull & subscribe to project data
	await terafy.init({
		app, // Provide app to bind against
		Vue // Provide the vue version to use
	});

	teraSyncPlugin(store).setTeraReady();

	app.$mount("#app");
})()
