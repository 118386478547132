const getDefaultMetadata = () => {
	return {
		// Project name
		title: "",
		settings: {
			deburr: true,
			weights: { // Indicates the fields to be extracted and their weights
				title: 1,
				abstract: 1,
				keywords: 1,
			},
			ignore: {
				common: true,
				numbers: true,
				duplicates: false,
			},
			min: {
				points: 3,
				unique: 0,
			},
			max: {
				results: 500, // Anything above this limit gets truncated by the resultsTruncate field
				resultsTruncate: 'points',
			},
			combineWords: 2, // How many word combinations should be examined (1=one word,2=two words etc.)
		}
	}
}

const state = {
	metadata: getDefaultMetadata()
}

const getters = {
	// Get the title
	getTitle: state => {
		return state.metadata.title;
	},
	getSettings: state => {
		return state.metadata.settings;
	}
}

const mutations = {
	reset(state) {
		state.metadata = getDefaultMetadata();
	},
	// Set metadata
	setMetadata(state, data) {
		// Set metadata
		Object.assign(state.metadata, data);
	},
	// Set title
	setTitle(state, title) {
		// Set title
		state.metadata.title = title;
	},
	// Set settings
	setSettings(state, settings) {
		state.metadata.settings = settings;
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations
}