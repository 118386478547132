<template>
	<div class="p-2 border border-secondary rounded">
		Import Progress:
		<b-progress :value="progress.parsing" :max="1" show-progress animated></b-progress>
		<div v-if="progress.parsing == 1" class="mt-3">
			<b-spinner class="mr-3" />
			<strong>Peforming word frequency analysis...</strong>
		</div>
	</div>
</template>

<script>
import { BProgress, BSpinner } from 'bootstrap-vue';

export default {
	name: "BaseProgressBar",
	components: {
		BProgress,
		BSpinner
	},
	data() {
		return {
			progress: {
				parsing: 0
			},
		}
	},
	mounted() {
		// Set progress
		this.$root.$on('set-progress', progressObj => {
			this.progress[progressObj.stage] = progressObj.progress
		});
	}
}
</script>