<template>
	<div class="mt-5">
		<b>To begin word frequency analysis, choose a library or drop it here</b>
		<tera-file-select
			:title="'Choose a citation library to examine...'"
			:filters="{library: true}"
			placeholder="Choose a citation library to examine..."
			@change="fileUpload"
			class="my-2"
			style="background: #FFF"
		/>
	</div>
</template>

<script>
import importMixin from "../mixins/importMixin"
import TeraFileSelect from '@iebh/tera-fy/widgets/tera-file-select.vue';

export default {
	name: 'FileUpload',
	mixins: [importMixin],
	components: {
		TeraFileSelect,
	},
	methods: {
		fileUpload(file) {
			this.importFile(file);
		}
	}
}
</script>
