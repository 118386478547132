import { mapGetters } from 'vuex';
import wordFreq from '../workers/wordFrequency.js';

export default {
	data() {
		return {
			progress: { current: 0 }
		}
	},
	computed: {
		...mapGetters({
			settings: "metadata/getSettings"
		})
	},
	methods: {
		async importFile(file) {
			this.$root.$emit('set-progress', { stage: "parsing", progress: 0 });
			this.$root.$emit('set-loading', true);

			let refs = await file.getRefs();

			let words = wordFreq({
				settings: this.settings,
				progress: this.progress
			}, refs);

			// Set store
			this.$store.commit('words/setWords', words);

			// Set loading to false
			this.$root.$emit('set-loading', false);
		},
	}
}
