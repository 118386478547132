<template>
	<div>
		<b-button v-b-modal.settings-modal><b-icon-gear-fill class="mr-2"></b-icon-gear-fill>Settings</b-button>
		<b-modal title="Settings" id="settings-modal" size="xl">
			<!-- Deburr -->
			<b-form-group
				label="Deburr"
				label-for="deburr"
				label-cols="2"
        label-align="right"
			>
				<template #description>
					Convert all
					<a href="https://en.wikipedia.org/wiki/Latin-1_Supplement_(Unicode_block)#Character_table" target="_blank">
						latin-1 supplementary letters
					</a>
					to basic latin letters and also remove
					<a href="https://en.wikipedia.org/wiki/Latin-1_Supplement_(Unicode_block)#Character_table" target="_blank">
						combining diacritical marks
					</a>.
					e.g. <code>ÕÑÎÔÑ</code> becomes <code>ONION</code>
				</template>
				<b-form-checkbox id="deburr" v-model="settings.deburr" switch size="lg"></b-form-checkbox>
			</b-form-group>
			<!-- Ignore common terms -->
			<b-form-group
				label="Ignore common terms"
				label-for="ignore-common-terms"
				label-cols="2"
        label-align="right"
			>
				<template #description>
					Convert all
					Remove all common English terms. e.g. <code>The</code>, <code>And</code>, <code>He</code>, <code>She</code>, <code>It</code>
				</template>
				<b-form-checkbox id="ignore-common-terms" v-model="settings.ignore.common" switch size="lg"></b-form-checkbox>
			</b-form-group>
			<!-- Ignore numbers -->
			<b-form-group
				label="Ignore numbers"
				label-for="ignore-numbers"
				label-cols="2"
        label-align="right"
			>
				<template #description>
					Remove all numeric terms along with English variants. e.g. <code>123</code>, <code>Twenty</code>, <code>Five</code>, <code>107,108.42</code>
				</template>
				<b-form-checkbox id="ignore-numbers" v-model="settings.ignore.numbers" switch size="lg"></b-form-checkbox>
			</b-form-group>
			<!-- Ignore duplicates -->
			<b-form-group
				label="Ignore duplicates"
				label-for="ignore-duplicates"
				label-cols="2"
        label-align="right"
			>
				<template #description>
					Only count words once per article
				</template>
				<b-form-checkbox id="ignore-duplicates" v-model="settings.ignore.duplicates" switch size="lg"></b-form-checkbox>
			</b-form-group>
			<!-- Max words -->
			<b-form-group
				label="Max words in phrase"
				label-for="max-words"
				label-cols="2"
        label-align="right"
			>
				<template #description>
					The maximum number of run-on words allowable within a phrase. If this is <code>1</code> only single words are returned, if <code>2</code> two words appearing together are listed and so on.
				</template>
				<b-form-input id="max-words" v-model="settings.combineWords"></b-form-input>
			</b-form-group>
			<!-- Min word freq -->
			<b-form-group
				label="Minimum word frequency"
				label-for="min-words"
				label-cols="2"
        label-align="right"
			>
				<template #description>
					Words with points below this number will not be shown. Set to <code>0</code> to display everything.
				</template>
				<b-form-input id="min-words" v-model="settings.min.points"></b-form-input>
			</b-form-group>
			<!-- Min uniqueness -->
			<b-form-group
				label="Minimum uniqueness"
				label-for="min-unique"
				label-cols="2"
        label-align="right"
			>
				<template #description>
					Words must occur in at least this number of references to be shown. Set to <code>0</code> to display everything.
				</template>
				<b-form-input id="min-unique" v-model="settings.min.unique"></b-form-input>
			</b-form-group>
			<!-- Weightings -->
			<b-form-group
				label="Weightings"
				description="Adjust the weighting of terms found in different sections of a reference"
				label-cols="2"
        label-align="right"
			>
				<table class="m-1 table table-bordered">
					<thead class="thead-dark">
						<tr>
							<th scope="col">Term</th>
							<th scope="col">Weighting</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="key in Object.keys(settings.weights)" :key="key">
							<th scope="row">{{key[0].toUpperCase() + key.substring(1)}}</th>
							<td class="p-1"><b-form-input id="min-unique" v-model="settings.weights[key]"></b-form-input></td>
						</tr>
					</tbody>
				</table>
			</b-form-group>
		</b-modal>
	</div>
</template>

<script>
import { BFormGroup, BButton, BModal, VBModal, BFormInput, BFormCheckbox, BIconGearFill } from 'bootstrap-vue';


export default {
	components: {
		BFormGroup,
		BButton,
		BModal,
		BFormInput,
		BIconGearFill,
		BFormCheckbox
	},
	directives: {
		BModal: VBModal
	},
	computed: {
		settings: {
			get() {
				return this.$store.getters['metadata/getSettings'];
			},
			set(val) {
				return this.$store.commit('metadata/setSettings', val);
			}
		}
	}
}
</script>